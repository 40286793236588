import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { MdOutlineContentCopy, MdOutlineInsertDriveFile } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import DeleteModal from "../../../../components/DeleteModal";
import {
  copyQuestionnaireService,
  deleteQuestionnaireService,
  getAllQuestionnaireDataSevice,
} from "./Questionnaire";
import { fontSize } from "@mui/system";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export const linkButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  backgroundColor: "#337ab7",
  padding: "8px",
  height: "35px",
  border: "none",
  color: "white",
  borderRadius: "5px",
  fontSize:"10px",
};

export const addButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  color: "#337ab7",
  backgroundColor: "white",
  padding: "8px",
  // fontSize: "15px",
  height: "35px",
  border: "2px solid #337ab7",
  borderRadius: "5px",
};

const Questionnaires = () => {
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);

  const [questionnaireData, setQuestionnaireData] = useState([]);

  const [pageSize, setPageSize] = useState(5);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [copyId, setCopyId] = useState(null);

  const deleteNotify = () =>
    toast.success("Questionnaire Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const copyNotify = () =>
    toast.success("Questionnaire Copied Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  useEffect(() => {
    fetchAllQuestionnaires();
  }, []);

  useEffect(() => {
    if (copyId) {
      copyQuestionnaire(copyId);
    }
  }, [copyId]);

  const fetchAllQuestionnaires = async () => {
    try {
      setLoad(true);
      const res = await getAllQuestionnaireDataSevice();
      if (res.status) {
        setLoad(false);
        setQuestionnaireData(res.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const questionnaireDatarows = questionnaireData.map((row) => ({
    id: row.id,
    internalName: row.internalName,
    code: row.questionnaireTypeName,
    pages: row.diaryPages?.length,
    action: row.id,
    delete: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "internalName",
      // hide: true,
      headerName: "Internal Name",
      width: 220,
      renderHeader: () => <div className="grid-heading">{"Internal Name"}</div>,
      renderCell: (params) => {
        const internalName = params.row.internalName;

        return (
          <div className="grid-body">
            <p>{internalName}</p>
          </div>
        );
      },
    },
    {
      field: "code",
      // hide: true,
      headerName: "Code",
      width: 220,
      renderHeader: () => <div className="grid-heading">{"Code"}</div>,
      renderCell: (params) => {
        const code = params.row.code;

        return (
          <div className="grid-body">
            <p>{code}</p>
          </div>
        );
      },
    },
    {
      field: "pages",
      // hide: true,
      headerName: "Pages",
      width: 220,
      renderHeader: () => <div className="grid-heading">{"Pages"}</div>,
      renderCell: (params) => {
        const pages = params.row.pages;

        return (
          <div className="grid-body">
            <p>{pages}</p>
          </div>
        );
      },
    },
    {
      field: "action",
      // hide: true,
      headerName: "Action",
      renderHeader: () => (
        <div className="grid-heading" style={{ marginLeft: "90px" }}>
          {"Action"}
        </div>
      ),
      width: "330",
      renderCell: (params) => {
        const id = params?.row.id;
        const pages = params.row.pages;
        return (
          <div
            style={{
              display: "flex",
              gap: "25px",
              alignItems: "center",
            }}
          >
            <button
              style={linkButtonStyle}
              onClick={() => {
                navigate(
                  `/study-management/questionnaires/edit-questionnaire`,
                  {
                    state: {
                      id: id,
                    },
                  }
                );
              }}
            >
              <FaPencilAlt color="white" size={15} />
            </button>
            <button
              style={linkButtonStyle}
              onClick={() => {
                setCopyId(id);
              }}
            >
              <MdOutlineContentCopy color="white" size={15} />
            </button>
            <button
              style={linkButtonStyle}
              onClick={() => {
                navigate(`/study-management/questionnaires/form-builder`, {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              {/* <MdOutlineInsertDriveFile  color="white" size={15} /> */}
              Design Questionaire
            </button>
          </div>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: "200",
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const copyQuestionnaire = async (copyId) => {
    setLoad(true);
    try {
      const res = await copyQuestionnaireService(copyId);
      if (res?.status) {
        setLoad(false);
        copyNotify();
        fetchAllQuestionnaires();
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
      requestFailed(err?.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteQuestionnaireService(deleteId);

      if (res.status) {
        setLoad(false);
        fetchAllQuestionnaires();
        setShowConfirmModal(false);
        deleteNotify();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/questionnaires">Questionnaires</Link> -
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Questionnaires</p>
              </div>
            </Col>
            <Col md={6}>
              <Link to="/study-management/questionnaires/create-questionnaire">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-md">
                    Create Questionnaire
                  </button>
                </div>
              </Link>
            </Col>
          </Row>

          <Box sx={{ height: 400, width: "100%", display: "flex" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allQuestionnaire-grid"
                rows={questionnaireDatarows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Questionnaire"}
        />
      )}
    </>
  );
};

export default Questionnaires;
