import axios from "axios";

const calcServer = axios.create({
    baseURL: process.env.REACT_APP_IDENTITY_URL,
    timeout: 100000,
    // headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
  });

export const createQuestion = async (formSubmissionArray, questionnaireId) => {
  const payload = formSubmissionArray.map((formSubmission) => ({
    json: JSON.stringify(formSubmission),
    questionTypeId: formSubmission.QuestionTypeId? parseInt(formSubmission.QuestionTypeId, 10) : 1,
    questionTranslationKey: formSubmission.id,
    description: formSubmission.Description,
    sequence: formSubmission.sequence,
    diaryPageId: formSubmission.DiaryPage,
    groupNumber: formSubmission.groupNumber,
    minValue: formSubmission.minValue || 0,
    maxValue: formSubmission.maxValue || 0,
    displayAlignment: formSubmission.displayAlignment,
    isActive: Boolean(formSubmission.isActive),
    isRequired: Boolean(formSubmission.required),
    width: formSubmission.width,
    marginTop: formSubmission.marginTop,
    marginLeft: formSubmission.marginLeft,
    marginBottom: formSubmission.marginBottom,
    marginRight: formSubmission.marginRight,
    textSize: formSubmission.textSize,
    patientAttributeConfigurationDetailId: formSubmission.PatientAttributeConfiguration,
    submitActionParameterId: formSubmission.SubmitActionParameter,
    inputDisplayText: formSubmission.InputDisplayText,
    exportDisplayOrder: formSubmission.ExportDisplayOrder,
    exportDisplayName: formSubmission.ExportDisplayName,
    choiceDisplayTypeId: formSubmission.ChoiceDisplayTypeId,
    isLandscapeOrientation: Boolean(formSubmission.IsLandscapeOrientation),
    minValueTextKey: formSubmission.id,
    isInstruction: Boolean(formSubmission.IsInstruction),
    enabledBusinessRuleId: formSubmission.EnableBusinessRuleId,
    textAlignment: formSubmission.displayAlignment,
    autoResizeChoices: Boolean(formSubmission.autoResizeChoices) || false,
    maxValueDisplayText: formSubmission.MaxValueDisplayText,
    summaryDisplayText: formSubmission.SummaryDisplayText,
    isDirty: Boolean(formSubmission.dirty),
    minValueDisplayText: formSubmission.MinValueTextKeyText,
    maxValueTextKey: formSubmission.id,
    choiceOrientation: formSubmission.KeepQuestionTextFixed,
    visibleBusinessRuleId: formSubmission.VisibleBusinessRuleId,
    questionnaireId: questionnaireId,
    columnCount: formSubmission.count,
    summaryDisplayTextKey: formSubmission.id,
    inputFieldTypeId: formSubmission.InputFieldTypeId? parseInt(formSubmission.InputFieldTypeId, 10): 1,
    questionDisplayText: formSubmission.props ? formSubmission.props.label : "",
    syncVersion: formSubmission.syncVersion,
    dataPointId: formSubmission.dataPointId,
    externalId: formSubmission.externalId,
    paddingCharacters: formSubmission.paddingCharacters,
    paddingLocation: formSubmission.paddingLocation,
    isSticky: Boolean(formSubmission.isSticky),
    displayOnConfirmation: Boolean(formSubmission.displayOnConfirmation),
  }));
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_IDENTITY_URL}/app/Question/CreateQuestion`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  try {
    const response = await axios(config);
    console.log("API Response: ", response.data);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const updateQuestion = async (formSubmissionArray, questionIdData, questionnaireId) => {
  const payload = formSubmissionArray.map((formSubmission) => {
    // Find matching ID based on maxValueTextKey
    const matchingId = questionIdData.find(item => item.maxValueTextKey === formSubmission.id);

    return {
    json: JSON.stringify(formSubmission),
    questionTypeId: formSubmission.QuestionTypeId? parseInt(formSubmission.QuestionTypeId, 10) : 1,
    questionTranslationKey: formSubmission.id,
    description: formSubmission.Description,
    sequence: formSubmission.sequence,
    diaryPageId: formSubmission.DiaryPage,
    groupNumber: formSubmission.groupNumber,
    minValue: formSubmission.minValue || 0,
    maxValue: formSubmission.maxValue || 0,
    displayAlignment: formSubmission.displayAlignment,
    isActive: Boolean(formSubmission.isActive),
    isRequired: Boolean(formSubmission.required),
    width: formSubmission.width,
    marginTop: formSubmission.marginTop,
    marginLeft: formSubmission.marginLeft,
    marginBottom: formSubmission.marginBottom,
    marginRight: formSubmission.marginRight,
    textSize: formSubmission.textSize,
    patientAttributeConfigurationDetailId: formSubmission.PatientAttributeConfiguration,
    submitActionParameterId: formSubmission.SubmitActionParameter,
    inputDisplayText: formSubmission.InputDisplayText,
    exportDisplayOrder: formSubmission.ExportDisplayOrder,
    exportDisplayName: formSubmission.ExportDisplayName,
    choiceDisplayTypeId: formSubmission.ChoiceDisplayTypeId,
    isLandscapeOrientation: Boolean(formSubmission.IsLandscapeOrientation),
    minValueTextKey: formSubmission.id,
    isInstruction: Boolean(formSubmission.IsInstruction),
    enabledBusinessRuleId: formSubmission.EnableBusinessRuleId,
    textAlignment: formSubmission.displayAlignment,
    autoResizeChoices: Boolean(formSubmission.autoResizeChoices) || false,
    maxValueDisplayText: formSubmission.MaxValueDisplayText,
    summaryDisplayText: formSubmission.SummaryDisplayText,
    isDirty: Boolean(formSubmission.dirty),
    minValueDisplayText: formSubmission.MinValueTextKeyText,
    maxValueTextKey: formSubmission.id,
    choiceOrientation: formSubmission.KeepQuestionTextFixed,
    visibleBusinessRuleId: formSubmission.VisibleBusinessRuleId,
    questionnaireId: questionnaireId,
    columnCount: formSubmission.count,
    summaryDisplayTextKey: formSubmission.id,
    inputFieldTypeId: formSubmission.InputFieldTypeId? parseInt(formSubmission.InputFieldTypeId, 10): 1,
    questionDisplayText: formSubmission.props ? formSubmission.props.label : "",
    syncVersion: formSubmission.syncVersion,
    dataPointId: formSubmission.dataPointId,
    externalId: formSubmission.externalId,
    paddingCharacters: formSubmission.paddingCharacters,
    paddingLocation: formSubmission.paddingLocation,
    isSticky: Boolean(formSubmission.isSticky),
    displayOnConfirmation: Boolean(formSubmission.displayOnConfirmation),
    id: matchingId?.id
  }});
  const config = {
    method: "put",
    url: `${process.env.REACT_APP_IDENTITY_URL}/app/Question/api/SaveEditedData`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  try {
    const response = await axios(config);
    console.log("API Response: ", response.data);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};



export const getQuestionByID = (QuestionnaireId) => {
    return new Promise((resolve, reject) => {
      calcServer
        .get(`app/Question/GetQuestionList?QuestionnaireId=${QuestionnaireId}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };


  export const DeleteQuestionById = (QuestionnaireId) => {
    return new Promise((resolve, reject) => {
      calcServer
        .get(`app/Question/DeleteQuestionsByQuestionnaireId?QuestionnaireId=${QuestionnaireId}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
