import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "@mui/material/Modal";

import { useGetRule } from "../../hooks/useRules";
// import { checkRuleValue } from "../../services/helpers"
// import "../Questionnaire/Questionnaire.css";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const ListView = React.forwardRef((props, ref) => {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
//   const { Rules, triggerUpdate } = useGetRule();

  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label
  } = props;
  const [isDisabled, setDisabled] = React.useState(disabled);
  const [isVisible, setVisible] = React.useState(true);

//   useEffect(() => {
//     const { form, formResponse } = Rules;
//     if (formResponse) {
//       const myId = props?.data.id;
//       if (form[myId] && form[myId]["dependsOn"] || form[myId] && form[myId]["useCalculation"]) {
//         const ruleData = checkRuleValue(form[myId], formResponse);
//         const { isVisible, isDisabled } = ruleData;
//         setVisible(isVisible);
//         setDisabled(isDisabled)

//       }
//     }
//   }, [Rules])
  return (
    <> {isVisible && (

      <div
        className={`${borderTop ? "borderTop " : ""} ${borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
      >
        {currentPage && (
          <div className="currentPage">
            {/* Page <p>{currentPage}</p> */}
            {!showTitle ? (
              <>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "2%",
                          }}
                        >
                          <h4>Enter Page Title</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            className="muiTextInput"
                            id="outlined-basic"
                            onChange={handleTitleName}
                            placeholder="Page Title"
                            variant="outlined"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "4%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={handleShowTitle}
                            className="saveTitleBtn"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Modal>
                {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                {/* <div className="addTitleBtn">
                  Container Title
                </div> */}
              </>
            ) : (
              <p>{titleName}</p>
            )}
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              className={`${disabled ? "labelDisabled" : "labelContainer"}`}
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
            >
              {label}
            </Typography>
            <Demo>
              <List dense={dense}>
                {generate(
                  <ListItem
                    ref={ref}
                    name={name}
                    defaultValue={defaultValue}
                    // disabled={isDisabled}
                    disabled={disabled}
                  >
                    <ListItemText
                      primary="Single-line item"
                      secondary={secondary ? "Secondary text" : null}
                      // disabled={isDisabled}
                      disabled={disabled}
                    />
                  </ListItem>
                )}
              </List>
            </Demo>
          </Grid>
        </Grid>
      </div>
    )
    }
    </>
  );
});

export default ListView;
