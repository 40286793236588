import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useGetRule } from "../../hooks/useRules";
// import { checkRuleValue } from "../../services/helpers";
// import "../Questionnaire/Questionnaire.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const MultiSelectCheckBox = React.forwardRef((props, ref) => {
  const [multiCheckOptions, setMultiCheckOptions] = React.useState(
    props?.data?.pageTitle?.split(",") || []
  );

  const [personName, setPersonName] = React.useState([]);
//   const { Rules, triggerUpdate } = useGetRule();

  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    props.set({
      key: props.data.id,
      value: typeof value === "string" ? value.split(",") : value,
    });
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
  const [isDisabled, setDisabled] = React.useState(disabled);
  const [isVisible, setVisible] = React.useState(true);

//   useEffect(() => {
//     const { form, formResponse } = Rules;
//     if (formResponse) {
//       const myId = props?.data.id;
//       if (
//         (form[myId] && form[myId]["dependsOn"]) ||
//         (form[myId] && form[myId]["useCalculation"])
//       ) {
//         const ruleData = checkRuleValue(form[myId], formResponse);
//         const { isVisible, isDisabled } = ruleData;
//         setVisible(isVisible);
//         setDisabled(isDisabled);
//       }
//     }
//   }, [Rules]);
  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${borderTop ? "borderTop " : ""} ${
            borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div className={`${disabled ? "labelDisabled" : "labelContainer"}`}>
            <p>{label}</p>
          </div>
          <div className="ageRangeInputBody">
            <div>
              <Select
                sx={{ width: "100%" }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput placeholder="Select Check Box" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                ref={ref}
                name={name}
                defaultValue={defaultValue}
                // disabled={isDisabled}
                disabled={disabled}
                placeholder="Select Check Box"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Multi Select CheckBox</em>
                </MenuItem>
                {multiCheckOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default MultiSelectCheckBox;
